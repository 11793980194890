import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import { Container, Row, Col } from "react-bootstrap";
import Service from "../Service/Service";
import styles from "./Services.module.scss";
import { isMobile } from "../../recoil/atoms";

// El componenete services recibe un array de servicios con los campos id, title, paragraphs, images y renderiza la lista y los muestra a todos, a su vez recibe de sus componentes hijos el id cuando un componente es ~hovereado~,

//hay que hacer update de la imagen hardcodeada que se pasa como props

const HCServices = [
  {
    id: 1,
    title: "Diseño de Experiencias",
    paragraph: "Todos los productos nuevos nacen a partir de un buen diseño. Contamos con perfiles para trabajar en Diseño UX, UI e Industrial.",
    image: "design",
    link:'/projects'
  },
  {
    id: 2,
    title: "Desarrollo de Software",
    paragraph: "Contamos con perfiles listos para trabajar en desarrollo de Aplicaciones, Websites, E-commerce, Blockchain, Realidad Aumentada y virtual, Machine Learning, Big data y más.",
    image: "software",
    link:'/projects'
  },
  {
    id: 3,
    title: "Ingeniería & Robótica",
    paragraph: "Nos apasiona crear productos físicos. Contamos con perfiles de alta calidad listos para desarrollar prototipos para proyectos de robótica / ingeniería.",
    image: "design",
    link:'/projects'
  }
];

const Services = ({ images }) => {

  const [services, setServices] = useState(false);
  // este estado indica cual servicio esta enfocado
  const [focusService, setFocusService] = useState(false);

  useEffect(() => {
    //REVISAR EL MAP Y PASAR EL SERVICIO ENFOCADO POR PROPS Y QUE LOS COMPONENTES SE OCULTEN EN VALIDACION

    //aqui recibe los servicios
    if (!services || !focusService) {
      setServices(HCServices);
    }
    if (!services) {
      setFocusService(false)
    }
  }, [focusService, services]);

  return (
    <Container className={`${styles.ContainerServices} `}>
      <Row className={styles.RowTitle}>
        <Col
          data-aos={!isMobile && "fade-right"}
          data-aos-duration="3000"
          className={styles.ColTitle}
        >
          <h3>
            Servicios<span>.</span>
          </h3>
        </Col>
      </Row>
      <Row className={styles.RowServices}>
        <Col
          data-aos={!isMobile && "fade-up-right"}
          data-aos-duration="3000"
          md={4}
          xs={12}
          className={styles.ColInfo}
        >
          <p>
            Conocé cuáles son nuestros <span>pilares de innovación </span>y las tecnologías que trabajamos en nuestros proyectos.
          </p>
        </Col>
        <Col
          data-aos={!isMobile && "fade-up-left"}
          data-aos-duration="2500"
          md={7}
          className={styles.ColLinks}
          onMouseLeave={() => {
            setFocusService(false);
            setServices(false);
          }}
        >
          {services &&
            services.map((mappedService) => {
              return (
                <div
                  // role="button"
                  // tabIndex={-1}
                  key={"mappedEl" + mappedService.id}
                  id={"mappedEl" + mappedService.id}
                  className={
                    //si existe focuservice
                    focusService
                      ? //si focusservice es igual al mapped
                        focusService.id === mappedService.id
                        ? `
                        ${styles.regularTitle}
                        ${styles.focusTitle}`
                        : `
                        ${styles.hiddenTitle} 
                         animated fadeOut
                        `
                      : styles.regularTitle
                  }
                  onMouseEnter={() => {
                    setFocusService(mappedService);
                  }}
                >
                  <div
                  // role="button"
                  // tabIndex={-1}
                  onClick={() => {
                    setFocusService(mappedService);
                  }}
                  onKeyPress={()=>{
                    setFocusService(mappedService);
                  }}
                  className={focusService ? styles.selfcentertitle : undefined}>
                    <Service
                      title={mappedService.title}
                      paragraph={mappedService.paragraph}
                      id={mappedService.id}
                      imageName={mappedService.image}
                      //update a la imagen
                      image={images}
                      isFocused={focusService}
                      link={mappedService.link}
                      setFocusService={setFocusService}
                      setServices={setServices}
                    />
                  </div>
                </div>
              );
            })}
        </Col>
        {images["bg"] && (
          <Img
            fluid={images["bg"].childImageSharp.fluid}
            className={`${styles.Background}`}
          />
        )}
      </Row>
    </Container>
  );
};

export default Services;
