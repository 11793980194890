import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import arrowViolet from "../../assets/images/arrowViolet.png";
import styles from "./Service.module.scss";
import Img from "gatsby-image";
import { useRecoilValue } from "recoil";
import { isMobileSelector } from "../../recoil/selectors";

const Service = ({
  title,
  paragraph,
  image,
  isFocused,
  imageName,
  id,
  setFocusService,
  setServices,
}) => {
  const [displayFull, setDisplayFull] = useState(false);

  const [hide, setHide] = useState(false);

  let isMobile = useRecoilValue(isMobileSelector);

  useEffect(() => {
    if (isFocused && isFocused.id === id) {
      setHide(false);
      setTimeout(() => {
        setDisplayFull(true);
      }, 50);
    } else if (isFocused && isFocused.id !== id) {
      setHide(true);
      setDisplayFull(false);
    }
  },[isFocused, id]);

  return (
    <Container
      className={`${styles.ContainerService} 
     ${hide && styles.ContainerHidden}`}
    >
      {/* link */}
      <Row className={`${styles.RowLink}`}>
        <Col className={`${styles.ColLink} `}>
          <div className={`${displayFull ? styles.title : null}`}>
            <p >
              {title}
              <span>.</span>
            </p>
          </div>
        </Col>
      </Row>
      <div>
        <Collapse
          timeout={500}
          className={`${
            !hide ? styles.collapse : styles.ContainerHidden
          } animated fadeIn`}
          in={displayFull}
        >
          <Row
            className={`${styles.RowInfo} 
          `}
          >
            <Col md={6} xs={6} className={styles.ColInfo}>
              <div>
                <Row className={`${styles.RowInfoParagraph}`}>
                  <Col xs={10}>
                    <p>{paragraph}</p>
                  </Col>
                </Row>
                <Row className={`${styles.RowInfoLink} `}>
                  <Col>
                    <Link to="/projects" className={styles.ContainerLink}>
                      <img
                        alt="arrow"
                        src={arrowViolet}
                        className={styles.ArrowBefore}
                      />
                      <p>Ver casos</p>
                      <img
                        alt="arrow"
                        src={arrowViolet}
                        className={styles.ArrowAfter}
                      />
                    </Link>
                    {isMobile && (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={()=>{
                          setFocusService(false)
                          setServices(false)
                        }}
                        onKeyPress={()=>{
                          setFocusService(false)
                          setServices(false)
                        }}
                        className={styles.ContainerLink}
                      >
                        <div className={styles.ContainerLink}>
                          <img
                            alt="arrow"
                            src={arrowViolet}
                            className={styles.ArrowBefore}
                          />
                          <p>Volver</p>
                          <img
                            alt="arrow"
                            src={arrowViolet}
                            className={styles.ArrowAfter}
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            {displayFull && (
              isMobile ? (
              <div style={{position: "relative", width: "100%", height: "100%"}}>
                <Img
                  alt="image_service"
                  fluid={image[imageName].childImageSharp.fluid}
                  className={styles.ImageServiceMobile}
                />
              </div>
              ):(
                <Col
                  data-aos={"fade-up-right"}
                  data-aos-duration="10"
                  data-aos-delay="10"
                  md={6}
                  xs={6}
                  className={styles.ColImage}
                >
                <Img
                  alt="image_service"
                  fluid={image[imageName].childImageSharp.fluid}
                  style={{ height: "100%", width: "100%" }}
                  className={styles.ImageService}
                />
              </Col>
              )
            )}
          </Row>
        </Collapse>
      </div>
    </Container>
  );
};

export default Service;
