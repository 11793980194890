import React from 'react';
import { Link } from 'gatsby';
import {Container, Row, Col} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import {ModeSelector} from '../../recoil/selectors'
import styles from './Contact.module.scss';
import arrowViolet from '../../assets/images/arrowViolet.png';
import cursor from '../../assets/images/cursor.png';
import bgRight from '../../assets/images/bgRight.png';

const Contact = () => {

    const mode = useRecoilValue(ModeSelector);

    return (
        <Container className={styles.ContainerContact}>
            {mode === 'dark'
                ?   <img alt='background_contact' src={bgRight} className={styles.ImgBackgorund} style={{opacity: '0.5'}}/>
                :   <img alt='background_contact' src={bgRight} className={styles.ImgBackgorund} style={{opacity: '0.1'}}/>}

            <Row className={styles.RowTitle}>
                <Col sm={'auto'} className={styles.ColTitle}>
                    <h3>Contactanos<span>.</span></h3>
                </Col>
            </Row>
        
            <Row className={styles.RowInfo}>
                <Col md={6} className={styles.ColGreet}>
                    <h1 className={styles.greet} >Hola</h1>
                    <h1>Hello</h1>
                    <h1>שלום</h1>
                    <h1>Ciao</h1>
                    <h1>Hallo...</h1>
                </Col>
                <Col md={4} className={styles.ColText}>
                    <Row>
                        <Col className={styles.ColParagraph}>
                            <p>Ponete en contacto con nosotros. Hablemos de proyectos, de incorporarte al equipo o saludanos.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={styles.ColLinks}>
                            {/* link 1 */}
                            <Row>
                                <Col xs={'auto'}>
                                    <Link 
                                     to='/contact' 
                                     state={{ 
                                         title1: 'Iniciar un', 
                                         title2:'proyecto', 
                                         text: 'Contanos tu idea para iniciar un proyecto', 
                                         mode:'Project' }} 
                                    className={`${styles.Link} ${styles.Link1}`}>
                                            <img alt="arrow" src={arrowViolet} className={styles.Arrow}/>
                                            <p>Iniciar un proyecto</p>
                                            <img alt='cursor' src={cursor} className={`${styles.Cursor} ${styles.cursor1}`}/>
                                    </Link>
                                </Col>
                            </Row>
                            {/* link 2 */}
                            <Row>
                                <Col xs={'auto'}>
                                    <Link 
                                     to='/contact' 
                                     state={{ 
                                         title1: '', 
                                         title2:'Hola', 
                                         text: 'Hola Itesa', 
                                         mode:'Saludo' }} 
                                    className={`${styles.Link} ${styles.Link2}`}>
                                        <img alt="arrow" src={arrowViolet} className={styles.Arrow}/>
                                        <p>Saludanos!</p>
                                        <img alt='cursor' src={cursor} className={`${styles.Cursor} ${styles.cursor2}`}/>
                                    </Link>
                                </Col>
                            </Row>
                            {/* link 3 */}
                            <Row>
                                <Col xs={'auto'}>
                                    <Link 
                                     to='/contact' 
                                     state={{ 
                                         title1: 'Sumate al', 
                                         title2:'equipo', 
                                         text: 'Trabajamos con equipos dinámicos y on demand para entregar a nuestros clientes la mejor experiencia.', 
                                         mode:'CV' }} 
                                    className={`${styles.Link} ${styles.Link3}`}>
                                        <img alt="arrow" src={arrowViolet} className={styles.Arrow}/>
                                        <p>Unite al Equipo</p>
                                        <img alt='cursor' src={cursor} className={`${styles.Cursor} ${styles.cursor3}`}/>
                                    </Link>
                                </Col>
                            </Row>

                        </Col>
                    </Row>                
                </Col>
            </Row>

        </Container>
    )

}

export default Contact