import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Container, Row, Col } from "react-bootstrap";
import { ModeSelector, isMobileSelector } from "../../recoil/selectors";
import "aos/dist/aos.css";
import loadable from '@loadable/component';
import AOS from "aos";
import { IsMobileDevice } from "../../utils/functions";
import styles from "./Methodology.module.scss";
import MobileDark1 from "../../assets/images/Methodology/MobileDark1.svg";
import MobileDark3 from "../../assets/images/Methodology/MobileDark3.svg";

const MethodologyDesktop = loadable(()=> import('./MethodologyDesktop'))

const Methodology = ({ images }) => {
  

  const mode = useRecoilValue(ModeSelector);
  const mobile = useRecoilValue(isMobileSelector);

  useEffect(() => {
    IsMobileDevice()
      ? AOS.init({ disable: false })
      : AOS.init({ duration: 1500 });
  });

  return (
    <div>
      {mobile ? (
         <div>
         {/* CONTAINER MOBILE */}
           <Container
           fluid
           className={`${styles.ContainerMethodology} onlymobile`}
           id="methodology"
         >
           {/* STEP 1 */}
   
           <Row
             data-aos="fade-right"
             data-aos-delay="550"
             className={`${styles.RowStep} ${styles.RowStep1}`}
           >
             {/* info */}
             <Col md={4} xs={8} className={styles.ColText}>
               {/* text */}
               <Row className={styles.Text}>
            
                 <Col
                   data-aos="fade-left"
                   data-aos-delay="550"
                   xs={"auto"}
                   className={`${styles.Number} ${styles.Number1} rellaxnumfirstmobile`}
                 >
                   <span>01</span>
             
                   
                 </Col>
                 <Col className="rellaxtitlefirstmobile">
                   <h3>
                     Seed<span>.</span>
                   </h3>
                 </Col>
                 <Col className="rellaxtextfirstmobile" md={12} xs={9}>
                   <p>
                     Tienes la idea y brief de un producto digital y quieres probar si funciona su concepto. Necesitas desarrollar un MVP rápido, a bajo costo y sin muchas integraciones, te entendemos.
                   </p>
                   <a href="/contact"  rel="noreferrer" className={styles.submit}>
                     Empieza ahora
                   </a>
                 </Col>
               </Row>
             </Col>
             {/* image */}
             <Col
               md={"auto"}
               xs={4}
               className={`${styles.ColImage} rellaximgfirstmobile`}
             >
                 <img alt="mdark" src={MobileDark1} />
             </Col>
           </Row>
   
           {/* STEP 2 */}
           <Row
             data-aos="fade-right"
             data-aos-delay="550"
             className={`${styles.RowStep} justify-content-start mt-4`}
           >
             {/* image */}
             <Col md={"auto"} xs={5} className={`${styles.ColImage} `}>
               {!mobile
                 ? mode === "dark"
                   ? images["DarkIlustration2"] && (
                       <img
                         alt="ilustration_methodology"
                         src={images["DarkIlustration2"].publicURL}
                       />
                     )
                   : images["LightIlustration2"] && (
                       <img
                         alt="ilustration_methodology"
                         src={images["LightIlustration2"].publicURL}
                       />
                     )
                 : images["MobileDark2"] && (
                     <img
                       alt="ilustration_methodology"
                       src={images["MobileDark2"].publicURL}
                     />
                   )}
             </Col>
             {/* info */}
             <Col md={4} xs={6} className={styles.ColText}>
               {/* text */}
               <Row className={`${styles.Text} ${styles.Text2}`}>
                 <Col
                   data-aos="fade-left"
                   data-aos-delay="850"
                   className={`${styles.Number} ${styles.Number2}`}
                 >
                   <span>02</span>
                 </Col>
                 <Col>
                   <h3>
                     Startup<span>.</span>
                   </h3>
                 </Col>
                 <Col md={9} xs={12}>
                   <p>
                     Tienes la idea y brief de un producto digital y quieres probar si funciona su concepto. Necesitas desarrollar un MVP rápido, a bajo costo y sin muchas integraciones, te entendemos.
                   </p>
                   <a href="/contact"  rel="noreferrer" className={styles.submit}>
                     Empieza ahora
                   </a>
                 </Col>
               </Row>
             </Col>
           </Row>
   
           {/* STEP 3 */}
           <br />
   
           <Row
             data-aos="fade-left"
             data-aos-delay="850"
             className={`${styles.RowStep} ${styles.RowStep3} justify-content-end mb-2`}
           >
             {/* info */}
             <Col md={4} xs={8} className={styles.ColText}>
               {/* text */}
               <Row className={styles.Text} style={{ marginBottom: "5vh" }}>
                 <Col
                   data-aos="fade-right"
                   data-aos-delay="1000"
                   className={`${styles.Number} ${styles.Number3}`}
                 >
                   <span>03</span>
                 </Col>
                 <Col>
                   <h3>
                     Scale up<span>.</span>
                   </h3>
                 </Col>
                 <Col xs={10}>
                   <p>
                     Cuentas con el presupuesto para invertir en tecnología y quieres escalar tu idea. Ya no estás probando si funciona el concepto, ya tienes el modelo de negocios, el concepto validado y sabes que será un éxito.¡Hagámoslo crecer juntos!
                   </p>
                   <a href="/contact"  rel="noreferrer" className={styles.submit}>
                     Empieza ahora
                   </a>
                 </Col>
               </Row>
             </Col>
             {/* image */}
   
             <Col md={"auto"} xs={5} className={`${styles.ColImage}`}>
                 <img alt="mdark" src={MobileDark3} className={styles.Ilustration3} />
             </Col>
           </Row>
            {/* STEP 4 */}
           {/* <Row
             data-aos="fade-right"
             data-aos-delay="550"
             className={`${styles.RowStep} justify-content-start mt-5`}
           >
              image
             <Col md={"auto"} xs={5} className={`${styles.ColImage} `}>
               {!mobile
                 ? mode === "dark"
                   ? images["DarkIlustration2"] && (
                       <img
                         alt="ilustration_methodology"
                         src={images["DarkIlustration2"].publicURL}
                       />
                     )
                   : images["LightIlustration2"] && (
                       <img
                         alt="ilustration_methodology"
                         src={images["LightIlustration2"].publicURL}
                       />
                     )
                 : images["MobileDark2"] && (
                     <img
                       alt="ilustration_methodology"
                       src={images["MobileDark2"].publicURL}
                     />
                   )}
             </Col>
              info
             <Col md={4} xs={6} className={styles.ColText}>
                text
               <Row className={`${styles.Text} ${styles.Text2}`}>
                 <Col
                   data-aos="fade-left"
                   data-aos-delay="850"
                   className={`${styles.Number} ${styles.Number2}`}
                 >
                   <span>04</span>
                 </Col>
                 <Col>
                   <h3>
                   Proyectos en escala<span>.</span>
                   </h3>
                 </Col>
                 <Col md={9} xs={12}>
                   <p>
                   Cada proyecto emplea un equipo de trabajo distinto. 
                   Esto nos permite trabajar con una enorme cantidad 
                   de proyectos en simultáneo sin demoras.
                   </p>
                 </Col>
               </Row>
             </Col>
           </Row>*/}
           <br />
         </Container>
       </div>
      ): <MethodologyDesktop images={images}/>}
    </div>
  );
};

export default Methodology;
