import React, { Fragment, useEffect, useState } from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout";
import About from "../components/About/About";
import Services from "../components/Services/Services";
import Last from "../components/Last/Last";
import Customers from "../components/Customers/Customers";
import { IsMobileDevice, CleanArrayImages } from "../utils/functions";
import { useRecoilState } from "recoil";
import { isMobile, Mode } from "../recoil/atoms";
import Contact from "../components/Contact/Contact";
import Banner from "../components/Banner/Banner";
import Methodology from "../components/Methodology/Methodology";
import Products from "../components/Products/Products";

const Home = ({ data }) => {
  const [images, setImages] = useState({});
  const [, setIsMobile] = useRecoilState(isMobile);
  const [, setMode] = useRecoilState(Mode);

  useEffect(() => {
    setIsMobile(IsMobileDevice());
    if (localStorage.theme) {
      setMode(localStorage.theme);
    } else {
      setMode("dark");
    }
    setImages(CleanArrayImages(data));
  }, [data, setMode, setIsMobile]);

  return (
    <Fragment>
      <SEO
        title="Innovation Hub"
        description="Somos una startup con base en Buenos Aires que provee equipos on demand de alta calidad para trabajar en proyectos de innovación y tecnología, con foco en diseño UX, desarrollo de software e ingeniería."
      />
      <Layout>
        <Banner />
        <div id="hub">
          <About
            images={Object.keys(images).length > 0 && images.About}
            projectsImgs={
              Object.keys(images).length > 0 && [
                images.Messi644,
                images.Blasfemia,
                images.Escappe,
                images.JuntosPorSudamerica,
                images.Lollapalooza,
                images.Patagonia,
              ]
            }
          />
        </div>
        <Methodology
          images={Object.keys(images).length > 0 && images.Methodology}
        />
        <div id="services">
          <Services
            images={Object.keys(images).length > 0 && images.Services}
          />
        </div>
        {/* <Last images={Object.keys(images).length > 0 && [images.Messi644,images.Blasfemia,images.Escappe,images.JuntosPorSudamerica,images.Lollapalooza,images.Patagonia]} /> */}
        {images.Customers && (
          <Customers
            images={Object.keys(images).length > 0 && images.Customers}
          />
        )}
        {/* <div id="products">
          <Products
            images={Object.keys(images).length > 0 && images.Products}
          />
        </div> */}
        <Contact />
      </Layout>
    </Fragment>
  );
};

export default Home;

export const data = graphql`
  query ImgHome {
    About: allFile(filter: { relativeDirectory: { eq: "About" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Customers: allFile(filter: { relativeDirectory: { eq: "Customers" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Methodology: allFile(filter: { relativeDirectory: { eq: "Methodology" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
    }
    Services: allFile(filter: { relativeDirectory: { eq: "Services" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Services: allFile(filter: { relativeDirectory: { eq: "Services" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Last: allFile(filter: { relativeDirectory: { eq: "Last" } }) {
      edges {
        node {
          name
          base
          publicURL
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Products: allFile(filter: { relativeDirectory: { eq: "Products" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Messi644: allFile(
      filter: { relativeDirectory: { eq: "Projects/Budweiser/Messi644" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Blasfemia: allFile(
      filter: { relativeDirectory: { eq: "Projects/Blasfemia" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Escappe: allFile(
      filter: { relativeDirectory: { eq: "Projects/Escappe" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    JuntosPorSudamerica: allFile(
      filter: {
        relativeDirectory: { eq: "Projects/Conmebol/JuntosPorSudamerica" }
      }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Lollapalooza: allFile(
      filter: { relativeDirectory: { eq: "Projects/Budweiser/Lollapalooza" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Patagonia: allFile(
      filter: { relativeDirectory: { eq: "Projects/Patagonia" } }
    ) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1920) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
