import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { Container, Row, Col, Button } from "react-bootstrap";
import HorizontalText from "../HorizontalText/HorizontalText";
import styles from "./About.module.scss";
import arrowDown from "../../assets/images/arrowGray.png";
import { useRecoilValue } from "recoil";
import { isMobileSelector, ModeSelector } from "../../recoil/selectors";
import Last from "../Last/Last";

const About = ({ images,projectsImgs }) => {
  const mode = useRecoilValue(ModeSelector);
  const mobile = useRecoilValue(isMobileSelector);
  return (
    <Container fluid className={styles.ContainerAbout}>
      {images["bg"] && (
        <Img
          style={{ opacity: mode === "dark" || !mode ? 0.6 : 1 }}
          fluid={
            mode === "dark" || !mode
              ? images["bg"].childImageSharp.fluid
              : images["bgnew"].childImageSharp.fluid
          }
          className={styles.Background}
        />
      )}
      <div className={styles.gradient} />
      <div data-aos="zoom-in" className={styles.ContainerHorizontalText}>
        <HorizontalText
          text={"Build-Measure-Learn. Repeat…"}
          // size={"5.4em"}
          // top={"22vh"}
          // width={"130%"}
          size={mobile ? '3em' : '5.4em'} 
          top={mobile ? '5vh' : '22vh'} 
          width={mobile ? '250%' : '130%'}
        />
      </div>
      <Row data-aos="zoom-in" className="m-0">
        <Col className="p-0 w-100 d-flex justify-content-center">
          <div className={styles.ContainerLine}>
            <div className={styles.VerticalLine} />
            <p>Quiénes Somos</p>
          </div>
        </Col>
      </Row>
      <Row data-aos="zoom-in" className={styles.RowText}>
        <Col md={"8"} xs={"10"} className={styles.ColText}>
          <h3>
              Potenciamos a emprendedores y startups para que puedan desarrollar sus MVPs y escalar sus productos digitales,
              ofreciéndoles soluciones integrales <span>value for money</span> que se adaptan a los distintos estadíos de sus emprendimientos.
          </h3>
        </Col>
      </Row>
      <Last images={projectsImgs}/>
      {/* <Row data-aos="zoom-in" className={styles.RowLink}>
        <Col xs={"auto"}>
          <Link
            to="/contact"
            state={{
              title1: "Sumate al",
              title2: "equipo",
              text:
                "Trabajamos con equipos dinámicos y on demand para entregar a nuestros clientes la mejor experiencia.",
              mode: "equipo",
            }}
          >
            <p className={styles.Link}>Sumate a ITESA</p>
          </Link>
        </Col>
      </Row> */}
      <Row data-aos="zoom-in" className={styles.RowArrow}>
        <Col className="text-center" xs={"auto"}>
          <Button
            onClick={() =>
              document
                .querySelector("#methodology")
                .scrollIntoView({ behavior: "smooth" })
            }
            className={styles.ButtonArrow}
          >
            <img alt="arrow" src={arrowDown} className={styles.Arrow} />
          </Button>
          <p>Cómo lo hacemos</p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
