import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {useRecoilValue} from 'recoil';
import {isMobileSelector} from '../../recoil/selectors';
import HorizontalText from '../HorizontalText/HorizontalText';
import styles from './Customers.module.scss';
import Img from "gatsby-image";


const Customers = ({images}) => {

    const mobile = useRecoilValue(isMobileSelector);

    return (
        <>
        <Container className={styles.ContainerCustomers}>
            <Row className={styles.RowParagraph}>
                <Col
                data-aos="zoom-out"
                className={styles.ColParagraph}>
                    <div className={styles.LineParagraph}/>
                    <p>Ya innovaron junto a nosotros</p>
                </Col>
            </Row>
            <Row className={styles.RowCustomers}>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate3`}>
                    {images["logo-abinbev"] && <Img fluid={images["logo-abinbev"].childImageSharp.fluid} className={styles.customerLogo} />}
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate2`}>
                    <Img fluid={images["logo-quilmes"] ? images["logo-quilmes"].childImageSharp.fluid : null} className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate`}>
                    <Img fluid={images["logo-patagonia"] ? images["logo-patagonia"].childImageSharp.fluid:null} className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate2`}>
                    <Img fluid={images["logo-corona"] ? images["logo-corona"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
                
            </Row>
            <Row className={styles.RowCustomers}>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate3`}>
                    <Img fluid={images["logo-budweiser"] ? images["logo-budweiser"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate2`}>
                    <Img fluid={images["logo-coca"] ? images["logo-coca"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate`}>
                    <Img fluid={images["logo-brahma"] ? images["logo-brahma"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate2`}>
                    <Img fluid={images["logo-ambev"] ? images["logo-ambev"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
            </Row>
            <Row className={styles.RowCustomers}>
            <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate3`}>
                    <Img fluid={images["logo-stella"] ? images["logo-stella"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
               
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate2`}>
                    <Img fluid={images["logo-bbc"] ? images["logo-bbc"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate`}>
                    <Img fluid={images["logo-colorado"] ? images["logo-colorado"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate2`}>
                    <Img fluid={images["logo-digital"] ? images["logo-digital"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
            </Row>
            <Row className={styles.RowCustomers}>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate3`}>
                    <Img fluid={images["logo-cruzroja"] ? images["logo-cruzroja"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate2`}>
                    <Img fluid={images["logo-gatorade"] ? images["logo-gatorade"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate`}>
                    <Img fluid={images["logo-conmebol"] ? images["logo-conmebol"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate2`}>
                    <Img fluid={images["logo-shell"] ? images["logo-shell"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
            </Row>
           
            <Row className={styles.RowCustomers}>
            <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate3`}>
                    <Img fluid={images["logo-simmons"] ? images["logo-simmons"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate3`}>
                    <Img fluid={images["logo-goose"] ? images["logo-goose"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate2`}>
                    <Img fluid={images["logo-maldini"] ? images["logo-maldini"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
                <Col
                data-aos="zoom-out"
                md={3} xs={6} className={`${styles.ColCustomers} pulsate`}>
                    <Img fluid={images["logo-temple"] ? images["logo-temple"].childImageSharp.fluid:null}className={styles.customerLogo} />
                </Col>
               
            </Row>
        </Container>
            <div className={styles.ContainerHorizontalText}>
                <HorizontalText text={"We choose to go to the Moon, not because it´s easy, but because it´s hard."} 
                                size={mobile ? '3em' : '5.4em'} 
                                top={mobile ? '5vh' : '10vh'} 
                                width={mobile ? '490%' : '230%'}/>
            </div>
    </>
    )

}

export default Customers