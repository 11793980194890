import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import { useRecoilValue } from "recoil";
import {isMobileSelector } from "../../recoil/selectors";
import arrowViolet from "../../assets/images/arrowViolet.png";
import arrowGray from "../../assets/images/arrowGray.png";
import styles from "./Banner.module.scss";
import "aos/dist/aos.css";
import loadable from '@loadable/component';
const Ball =  loadable(()=> import("./Ball"));

const Banner = () => {
  const mobile = useRecoilValue(isMobileSelector);

  return (
    <Container fluid className={styles.ContainerBanner}>

      {/* background images */}
      {/* <div>
        <div className={styles.card1 + " tiltloop"} />
        <div className={styles.card2 + " tiltloop1"} />
      </div> */}
      <div className={styles.ContainerBall}>
        <Ball />
      </div>

      <Row className={styles.ContainerText}>
        <Row
          data-aos="fade-right"
          data-aos-delay="250"
          className={styles.RowWelcome}
        >
          <Col>
            <p>Bienvenidos al hub de innovación.</p>
          </Col>
        </Row>

        <Row
          data-aos="fade-right"
          data-aos-delay="450"
          className={styles.RowTitle}
        >
          <Col md="6" xs="12">
            <h1>
              Tailor-made innovation
              for <span>entrepreneurs.</span>
            </h1>
          </Col>
        </Row>

        <Row
          data-aos="fade-right"
          data-aos-delay="650"
          className={styles.RowParagraph}
        >
          <Col md="5" xs="7" className="pr-0">
            <h2>
              Como venture builders, trabajamos con startups y emprendedores que buscan potenciar sus proyectos tech ofreciéndoles planes que se ajusten a la etapa del proyecto y al capital disponible.
            </h2>
          </Col>
        </Row>
        <Row className={styles.RowLinks}>
          <Col md="3" xs="12" className="d-flex justify-content-start">
            <Link to="/projects" className={styles.ContainerLink}>
              <img
                alt="arrow"
                src={arrowViolet}
                className={styles.ArrowBefore}
              />
              <p>Ver casos</p>
              <img
                alt="arrow"
                src={arrowViolet}
                className={styles.ArrowAfter}
              />
            </Link>
          </Col>

          <Col md="6" xs="12" className="d-flex justify-content-start">
            <Link
              to="/contact"
              state={{
                title1: "Iniciar un",
                title2: "proyecto",
                text: "Contanos tu idea para iniciar un proyecto",
                mode: "proyect",
              }}
              className={styles.ContainerLinkGray}
            >
              {mobile ? (
                <img
                  alt="arrow"
                  src={arrowViolet}
                  className={styles.ArrowBefore}
                />
              ) : (
                <img
                  alt="arrow"
                  src={arrowGray}
                  className={styles.ArrowBefore}
                />
              )}
              <p>Iniciar un Proyecto</p>
              <img
                alt="arrow"
                src={arrowViolet}
                className={styles.ArrowAfter}
              />
            </Link>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default Banner;
